<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8 px-3">
          <h2 class="doc-title">Cookie Policy</h2>
          <h3 class="doc-sub-title">INFORMATION ABOUT OUR USE OF COOKIES</h3>
     <p class="doc-pera">
           
Fuse.Gold Limited (hereinafter referred to as “we” and/or “our” where the context
so permits) website uses cookies to distinguish you from other users of our
website. This helps us to provide you with a good experience when you browse
our website and also allows us to improve our site. By continuing to browse our
website, you are agreeing to our use of cookies.
     </p>
     <p class="doc-pera">
      
A cookie is a small file of letters and numbers that we store on your browser or
the hard drive of your computer if you agree. Cookies contain information that is
transferred to your computer&#39;s hard drive.
     </p>
<p class="doc-pera">
  We use the following cookies:
</p>
<ul>
  <li>
    <p class="doc-pera pl-4">
      <b> Strictly necessary cookies. </b>
     These are cookies that are required for the
operation of our website. They include, for example, cookies that enable you
to log into secure areas of our website, use a shopping cart or make use of e-
billing services.
    </p>
  </li>
  <li>
    <p class="doc-pera pl-4">
      <b>
         Analytical/performance cookies.
      </b>
      They allow us to recognise and count the
number of visitors and to see how visitors move around our website when
they are using it. This helps us to improve the way our website works, for
example, by ensuring that users are finding what they are looking for easily.
    </p>
  </li>
  <li> 
    <p class="doc-pera pl-4">
      <b> Functionality cookies.</b>
These are used to recognise you when you return to
our website. This enables us to personalise our content for you, greet you by
name and remember your preferences (for example, your choice of language
or region).
    </p>
  </li>
  <li>
    <p class="doc-pera"><b>
      Targeting cookies.
    </b>
    These cookies record your visit to our website, the pages
you have visited and the links you have followed. We will use this information
to make our website and the advertising displayed on it more relevant to your
interests. We may also share this information with third parties for this
purpose.
    </p>
  </li>
</ul>
<p class="doc-pera">
  You block cookies by activating the setting on your browser that allows you to
refuse the setting of all or some cookies. However, if you use your browser
settings to block all cookies (including essential cookies) you may not be able to
access all or parts of our site.
</p>

        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   },
    name: "CookiePolicy",
}
</script>

<style>

</style>